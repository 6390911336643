.pafenixtion {
  display: flex;
  @include list-unstyled();
}

.page-link {
  position: relative;
  display: block;
  color: $pafenixtion-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $pafenixtion-bg;
  border: $pafenixtion-border-width solid $pafenixtion-border-color;
  @include transition($pafenixtion-transition);

  &:hover {
    z-index: 2;
    color: $pafenixtion-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: $pafenixtion-hover-bg;
    border-color: $pafenixtion-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: $pafenixtion-focus-color;
    background-color: $pafenixtion-focus-bg;
    outline: $pafenixtion-focus-outline;
    box-shadow: $pafenixtion-focus-box-shadow;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $pafenixtion-margin-start;
  }

  &.active .page-link {
    z-index: 3;
    color: $pafenixtion-active-color;
    @include gradient-bg($pafenixtion-active-bg);
    border-color: $pafenixtion-active-border-color;
  }

  &.disabled .page-link {
    color: $pafenixtion-disabled-color;
    pointer-events: none;
    background-color: $pafenixtion-disabled-bg;
    border-color: $pafenixtion-disabled-border-color;
  }
}


//
// Sizing
//
@include pafenixtion-size($pafenixtion-padding-y, $pafenixtion-padding-x, null, $pafenixtion-border-radius);

.pafenixtion-lg {
  @include pafenixtion-size($pafenixtion-padding-y-lg, $pafenixtion-padding-x-lg, $font-size-lg, $pafenixtion-border-radius-lg);
}

.pafenixtion-sm {
  @include pafenixtion-size($pafenixtion-padding-y-sm, $pafenixtion-padding-x-sm, $font-size-sm, $pafenixtion-border-radius-sm);
}
